import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import Offer from './pages/Offer'
import UnderConstruction from './pages/UnderConstruction'

const Main: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/realizacje' element={<UnderConstruction />} />
      <Route path='/kontakt' element={<Contact />} />
      <Route path='/oferta' element={<Offer />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default Main
