import { Route } from './Route'

export const menuItems: Array<Route> = [
  {
    path: '/',
    title: 'O firmie',
  },
  {
    path: '/oferta',
    title: 'Oferta',
  },
  {
    path: '/realizacje',
    title: 'Realizacje',
  },
  {
    path: '/kontakt',
    title: 'Kontakt',
  },
]
