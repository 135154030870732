import { FC } from 'react'
import { MDBCol } from 'mdb-react-ui-kit'
import './index.scss'
import clsx from 'clsx'
import { Offer } from '../../types/Offer/Offer'

export type OfferElementProps = Pick<Offer, 'icon' | 'title' | 'content'>

const OfferElement: FC<OfferElementProps> = ({ icon, title, content }) => {
  return (
    <MDBCol size={10} md={3} className='offer-element' data-testid='offer-element'>
      <div className='offer-element-top'>
        <i className={clsx('offer-element-icon', icon)} />
        <p className='offer-element-title'>{title}</p>
      </div>
      <p className='offer-element-content'>{content}</p>
    </MDBCol>
  )
}

export default OfferElement
