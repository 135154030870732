import React from 'react'
import './index.scss'
import clsx from 'clsx'
import { BackgroundColor } from '../Heading'

interface ParagraphProps {
  content?: string
  backgroundColor?: BackgroundColor
  center?: boolean
  children?: React.JSX.Element
}

const Paragraph: React.FC<ParagraphProps> = ({ content, backgroundColor, center, children }) => {
  return (
    <p
      className={clsx(
        'app-paragraph',
        backgroundColor === 'primary' && 'primary-background',
        center && 'text-center',
      )}
      data-testid='paragraph'
    >
      <>
        {content && content}
        {!content && children}
      </>
    </p>
  )
}

export default Paragraph
