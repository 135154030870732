import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../Button'
import { MDBInput } from 'mdb-react-ui-kit'
import clsx from 'clsx'
import { useContactMessage } from '../../services/contactMessage/useContactMessage'
import { ContactMessage } from '../../types/ContactMessage/ContactMessage'
import { toast } from 'react-toastify'

const ContactForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<ContactMessage>()

  const { mutate, isLoading } = useContactMessage()

  const [contantHasValue, setContentHasValue] = useState<boolean>(false)

  useEffect(() => {
    const contentValue = watch((value, { name, type }) => {
      if (name === 'content' && type === 'change') {
        setContentHasValue(value.content !== '')
      }
    })
    return () => contentValue.unsubscribe()
  }, [watch()])

  const onSubmit = (data: ContactMessage) => {
    const message = {
      ...data,
    }
    mutate(message, {
      onSuccess: () => {
        reset()
        setContentHasValue(false)
        toast.success('Wiadomość została wysłana')
      },
      onError: () => {
        toast.error('Nie udało się wysłać wiadomości')
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MDBInput
        label='Imię'
        autoComplete='given-name'
        className={clsx('mb-4', errors.firstName && 'is-invalid')}
        {...register('firstName', { required: true })}
        disabled={isLoading}
        data-testid='first-name-input'
      />
      <MDBInput
        label='Nazwisko'
        autoComplete='family-name'
        className={clsx('mb-4', errors.lastName && 'is-invalid')}
        {...register('lastName', { required: true })}
        disabled={isLoading}
        data-testid='last-name-input'
      />
      <MDBInput
        label='Adres e-mail'
        type='email'
        autoComplete='email'
        className={clsx('mb-4', errors.email && 'is-invalid')}
        {...register('email', {
          required: true,
          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        })}
        disabled={isLoading}
        data-testid='email-input'
      />
      <MDBInput
        label='Temat wiadomości'
        className={clsx('mb-4', errors.subject && 'is-invalid')}
        {...register('subject', { required: true })}
        disabled={isLoading}
        data-testid='subject-input'
      />
      <div className='form-outline'>
        <textarea
          className={clsx(
            'form-control mb-4',
            errors.content && 'is-invalid',
            contantHasValue && 'active',
          )}
          {...register('content', { required: true, minLength: 20 })}
          rows={6}
          id='content'
          disabled={isLoading}
          data-testid='content-input'
        ></textarea>
        <label className='form-label' htmlFor='content'>
          Treść wiadomości
        </label>
        <div className='form-notch'>
          <div className='form-notch-leading'></div>
          <div
            className='form-notch-middle'
            style={{
              width: '115px',
            }}
          ></div>
          <div className='form-notch-trailing'></div>
        </div>
      </div>
      <div className='mb-4'>
        <Button text='Wyślij' formType='submit' disabled={isLoading} />
      </div>
    </form>
  )
}

export default ContactForm
