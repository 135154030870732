import OfferElement, { OfferElementProps } from '../OfferElement'
import { FC } from 'react'
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'

interface OfferHolderProps {
  elements: Array<OfferElementProps>
}

const OfferHolder: FC<OfferHolderProps> = ({ elements }) => {
  return (
    <MDBContainer className='py-5 px-0' fluid data-testid='offer-holder'>
      <MDBRow className='justify-content-center gap-5'>
        {elements.map((elem, index) => (
          <OfferElement icon={elem.icon} title={elem.title} content={elem.content} key={index} />
        ))}
      </MDBRow>
    </MDBContainer>
  )
}

export default OfferHolder
