import React from 'react'
import { MDBBtn } from 'mdb-react-ui-kit'

import './index.scss'

interface ButtonProps {
  text: string
  callable?: CallableFunction
  type?: 'primary' | 'secondary'
  formType?: 'submit' | 'button'
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({
  text,
  callable = () => {
    return
  },
  type = 'primary',
  formType = 'button',
  disabled = false,
}) => {
  const onClick = () => {
    callable()
  }

  return (
    <MDBBtn
      onClick={onClick}
      className={'btn-app ' + (type === 'primary' ? 'btn-app-primary' : 'bnt-app-secondary')}
      data-testid='button-component'
      type={formType}
      disabled={disabled}
    >
      {text}
    </MDBBtn>
  )
}

export default Button
