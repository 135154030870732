import React from 'react'
import banner from '../../assets/images/baner.png'

import './index.scss'

interface BannerProps {
  slogan?: string
}

const Banner: React.FC<BannerProps> = ({ slogan }) => {
  return (
    <div className='holder' data-testid='banner-holder'>
      <div
        className='banner'
        style={{
          backgroundImage: `url(${banner})`,
        }}
      ></div>
      {slogan && (
        <div className='slogan' data-testid='banner-slogan'>
          {slogan}
        </div>
      )}
    </div>
  )
}

export default Banner
