import { FC } from 'react'
import './index.scss'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import Heading from '../Heading'

const Loader: FC = () => {
  return (
    <MDBRow className='my-5'>
      <MDBCol size={12} className='d-flex justify-content-center flex-column align-items-center'>
        <div className='lds-facebook'>
          <div />
          <div />
          <div />
        </div>
      </MDBCol>
      <Heading size={'h2'} content='Trwa ładowanie...' center />
    </MDBRow>
  )
}

export default Loader
