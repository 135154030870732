import React, { FC } from 'react'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import Heading from '../../components/Heading'
import OfferHolder from '../../components/OfferHolder'
import { useOffers } from '../../services/offers/useOffers'
import Loader from '../../components/Loader'
import MetaTags from 'react-meta-tags'

const Offer: FC = () => {
  const { data, isLoading } = useOffers()

  return (
    <div data-testid='offer-page'>
      <MetaTags>
        <title>Oferta | Przedsiębiorstwo &quot;Eko-chem&quot; sp. z o.o.</title>
      </MetaTags>
      <MDBContainer className='p-0'>
        <MDBRow className='mx-0 p-0'>
          <MDBCol size={12} className='p-0'>
            <Heading size={'h1'} content={'Nasza oferta'} center special />
            {/* <Heading*/}
            {/*  size={'h3'}*/}
            {/*  content={*/}
            {/*    'Tutaj będzie jakaś zachęta co działa jak - sprawdź co mamy do zaoferowania.'*/}
            {/*  }*/}
            {/*  center*/}
            {/* / */}
          </MDBCol>
          <MDBCol size={12} className='p-0'>
            {isLoading && <Loader />}
            {!isLoading && data && <OfferHolder elements={data['hydra:member']} />}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default Offer
