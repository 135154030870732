import { ContactMessage } from '../../types/ContactMessage/ContactMessage'
import { request } from '../request'
import { Routes } from '../../shared/constants'
import { useMutation } from 'react-query'
import { ApiErrorResponse } from '../../types/Errors/ApiErrorResponse'

export const getContactMessage = async (data: ContactMessage) => {
  return await request<Response>(Routes.API.ContactMessage, false, 'POST', data)
}

export const useContactMessage = () => {
  return useMutation<Response, ApiErrorResponse, ContactMessage>((data) => getContactMessage(data))
}
