import React from 'react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'

import './index.scss'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import MetaTags from 'react-meta-tags'

const NotFound: React.FC = () => {
  const navigate = useNavigate()

  const goHome = () => {
    navigate('/')
  }

  return (
    <MDBRow className='not-found py-4' data-testid='not-found-page'>
      <MetaTags>
        <title>Strona nie istnieje | Przedsiębiorstwo &quot;Eko-chem&quot; sp. z o.o.</title>
      </MetaTags>
      <MDBCol size={12}>
        <div className='p-4 row'>
          <div className='error-container'>
            <p className='h1 error-code'>404</p>
            <p className='error-status'>
              <span>UPS!</span> Ta strona nie istnieje
            </p>
            <p className='lead error-description pb-2'>
              Strona o podanym adresie nie została odnaleziona.
            </p>
            <Button text='Wróć do strony głównej' callable={goHome} />
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  )
}

export default NotFound
