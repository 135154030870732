import React, { useState } from 'react'
import logo from '../../assets/images/logo.svg'
import {
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarNav,
  MDBNavbarToggler,
} from 'mdb-react-ui-kit'
import { menuItems } from '../../shared/Router/routerConfig'
import './index.scss'
import { NavLink } from 'react-router-dom'

const Navbar: React.FC = () => {
  const [showBasic, setShowBasic] = useState<boolean>(false)

  return (
    <MDBNavbar expand='lg' light bgColor='light' className='p-0' data-testid='navbar'>
      <MDBContainer fluid>
        <MDBNavbarBrand href='/' className='p-0 m-0 ms-md-5 ms-1'>
          <img src={logo} height='100px' loading='lazy' alt='logo' />
        </MDBNavbarBrand>
        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => {
            setShowBasic(!showBasic)
          }}
          data-testid='toggle'
        >
          {showBasic && <MDBIcon icon='times' size='2x' className='toggler-icon' />}
          {!showBasic && <MDBIcon icon='bars' size='2x' className='toggler-icon' />}
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className='mr-auto justify-content-center gap-md-5'>
            {menuItems.map(({ path, title }, key) => (
              <MDBNavbarItem key={path}>
                <NavLink
                  to={path}
                  className={({ isActive }) => {
                    return 'nav-link ' + (isActive ? 'active' : '')
                  }}
                  onClick={() => {
                    setShowBasic(false)
                  }}
                  data-testid={'link_' + key}
                >
                  {title}
                </NavLink>
              </MDBNavbarItem>
            ))}
          </MDBNavbarNav>
        </MDBCollapse>
        <MDBNavbarBrand
          style={{
            opacity: 0,
            cursor: 'auto',
          }}
          className='p-0 m-0 me-5 d-md-block d-none'
        >
          <img src={logo} height='100px' loading='lazy' alt='logo' />
        </MDBNavbarBrand>
      </MDBContainer>
    </MDBNavbar>
  )
}

export default Navbar
