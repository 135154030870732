import React from 'react'
import './index.scss'
import clsx from 'clsx'

export type HeadingSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
export type BackgroundColor = 'primary' | 'white'

interface HeadingProps {
  size: HeadingSize
  content: string
  backgroundColor?: BackgroundColor
  center?: boolean
  noMargin?: boolean
  special?: boolean
  subSpecial?: boolean
}

const Heading: React.FC<HeadingProps> = ({
  size,
  content,
  backgroundColor = 'white',
  center = false,
  noMargin = false,
  special = false,
  subSpecial = false,
}) => {
  return (
    <>
      <p
        className={clsx(
          'app-heading',
          size,
          backgroundColor === 'primary' && 'primary-background',
          center && 'text-center',
          noMargin && 'm-0',
          special && 'heading-special',
          subSpecial && 'heading-sub-special',
        )}
        data-testid='heading'
      >
        {content}
      </p>
      {subSpecial && <div className='sub-special-decorator' />}
    </>
  )
}

export default Heading
