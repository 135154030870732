import React, { FC } from 'react'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import MetaTags from 'react-meta-tags'
import underConstruction from '../../assets/images/g10.svg'
import Heading from '../../components/Heading'
import './index.scss'

const UnderConstruction: FC = () => {
  return (
    <MDBRow className='py-4' data-testid='under-construction'>
      <MetaTags>
        <title>Strona w budowie | Przedsiębiorstwo &quot;Eko-chem&quot; sp. z o.o.</title>
      </MetaTags>
      <MDBCol size={12}>
        <MDBContainer>
          <MDBRow>
            <MDBCol size={12} className='d-flex flex-column align-items-center'>
              <Heading size='h1' special content={'Strona w budowie'} />
              <img
                src={underConstruction}
                loading='lazy'
                alt='strona w budowie'
                className='under-construction-image'
              />
              <p className='mb-5 photo-copyright'>
                <a href='https://pl.freepik.com/darmowe-wektory/ilustracja-koncepcja-w-budowie_8497412.htm#page=4&query=under%20construction&position=5&from_view=search&track=ais'>
                  Obraz autorstwa storyset
                </a>{' '}
                na Freepik
              </p>
              <Heading size='h3' content={'Zapraszamy już wkrótce'} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCol>
    </MDBRow>
  )
}

export default UnderConstruction
