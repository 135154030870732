import React from 'react'
import aboutCompany from '../../assets/images/about-company.png'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'

import './index.scss'
import Heading from '../Heading'
import Paragraph from '../Paragraph'

const AboutUs: React.FC = () => {

  return (
    <MDBRow className='mt-4 mt-md-5 mb-0 mb-md-5 mx-0 px-md-5 gap-0' data-testid='about-us-row'>
      <MDBCol size={12} md={7} className='about-company'>
        <Heading size='h1' content='O firmie' subSpecial />
        <Paragraph>
          <>
            Nasza firma istnieje na rynku od 1991 r. i świadczy usługi w branży instalacyjnej.
            <br/>
            <br/>
            Działania firmy obejmują głównie projektowanie, budowę i modernizację węzłów cieplnych, kotłowni gazowych, budowę oraz modernizację wewnętrznych instalacji centralnego ogrzewania, wewnętrznych instalacji wodno-kanalizacyjnych oraz wewnętrznych instalacji gazowych. Zapewniamy także usługi serwisowe dotyczące w szczególności węzłów cieplnych oraz kotłowni gazowych.
            <br/>
            <br/>
            O wielu lat współpracujemy ze znanymi w branży producentami oraz dostawcami urządzeń. Naszą kadrę tworzy sprawdzony zespół pracowników jak również fachowców z zewnątrz, którzy dokładają wszelkich starań w celu zadowolenia naszych klientów.
            <br/>
            <br/>
            Grono naszych klientów stanowią głównie wspólnoty i spółdzielnie mieszkaniowe. Podejmujemy także współpracę z klientami indywidualnymi.
            <br/>
            <br/>
            Ważnym aspektem naszych działań jest dbałość o środowisko. Z tego powodu od kilku lat aktywnie pomagamy zarządcom nieruchomości, wspólnotom mieszkaniowym oraz indywidualnym klientom w pozyskaniu <strong>dotacji</strong> celowych na likwidację źródeł niskiej emisji i zastąpienie ich źródłami proekologicznymi z programu <strong>KAWKA BIS</strong> realizowanego przez Miasto Poznań.
            <br/>
            <br/>
            Służymy fachowym doradztwem technicznym w oparciu o najnowszą wiedzę oraz zdobyte doświadczenie.
          </>
        </Paragraph>
      </MDBCol>
      <MDBCol size={12} md={5} className='about-company-photo p-0'>
        <img src={aboutCompany} alt='About company image' />
      </MDBCol>
    </MDBRow>
  )
}

export default AboutUs
