import { ApiErrorResponse } from '../types/Errors/ApiErrorResponse'

export const request = async <T>(
  uri = '/',
  isFile = false,
  method: 'GET' | 'POST' = 'GET',
  body: object = {},
): Promise<T> => {
  const response = await fetch(uri, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: Object.keys(body).length > 0 ? JSON.stringify(body) : null,
  })

  if ([204, 202].indexOf(response.status) !== -1) {
    return response as T
  }

  const result = isFile ? await response.blob() : await response.json()

  if (response.ok) {
    return result
  }

  throw {
    type: 'Error',
    message: result['hydra:title'] || null,
    description: result['hydra:description'] || null,
    status: response.status,
    body: JSON.stringify(result),
  } as ApiErrorResponse
}
