import React from 'react'
import { MDBFooter, MDBContainer, MDBCol, MDBRow, MDBIcon } from 'mdb-react-ui-kit'
import './index.scss'
import { NavLink } from 'react-router-dom'
import { menuItems } from '../../shared/Router/routerConfig'
import Heading from '../Heading'

const Footer: React.FC = () => {
  return (
    <MDBFooter className='footer pt-4 pb-3' data-testid='footer'>
      <MDBContainer fluid={true} className='footer-container'>
        <MDBRow className='footer-row'>
          <MDBCol lg='6' md='12' className='address-col'>
            <Heading
              size='h2'
              content='Przedsiębiorstwo „Eko-chem” Spółka&nbsp;z o.o.'
              backgroundColor='primary'
            />
            <div className='address-elem my-4'>
              <MDBIcon className='icon' fas icon='map-marker-alt' size='1x' />
              <p>
                ul. Grunwaldzka 19 <br />
                60-782 Poznań
              </p>
            </div>
            <div className='address-elem my-4'>
              <MDBIcon className='icon' fas icon='envelope' size='1x' />
              <p>
                <a href='mailto:biuro@eko-chem.com'>biuro@eko-chem.com</a>
              </p>
            </div>
            <div className='address-elem my-4'>
              <MDBIcon className='icon' fas icon='phone-alt' size='1x' />
              <p>
                <a href='tel:+48606786436'>+48 606 786 436</a>
              </p>
            </div>
          </MDBCol>

          <MDBCol lg='3' md='6' className='mb-4 link-col d-none d-md-block'>
            <ul className='list-unstyled'>
              {menuItems.map(({ path, title }) => (
                <li key={path}>
                  <NavLink to={path}>
                    <MDBIcon fas icon='angle-double-right' /> {title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </MDBCol>
        </MDBRow>
        <div className='text-center copyright'>
          <p>
            Copyright &copy; {new Date().getFullYear()} eko-chem <span className='divider'>|</span>{' '}
            <br className='d-block d-md-none' /> made with{' '}
            <MDBIcon fas icon='heart' className='heart-icon' /> by{' '}
            <a href='https://in2dev.pl'>in2dev</a>
          </p>
        </div>
      </MDBContainer>
    </MDBFooter>
  )
}

export default Footer
