import { request } from '../request'
import { Offers } from '../../types/Offer/Offers'
import { Routes } from '../../shared/constants'
import { useQuery } from 'react-query'
import { ApiErrorResponse } from '../../types/Errors/ApiErrorResponse'
import { queryKeys } from '../../shared/queryKeys'

export const getOffers = async () => {
  return await request<Offers>(Routes.API.Offers)
}

export const useOffers = () => {
  return useQuery<Offers, ApiErrorResponse>(queryKeys.offers, () => getOffers())
}
